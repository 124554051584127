import { useEffect } from 'react';

const NotFound = () => {

  useEffect(() => {
    document.title = 'Mike van Egmond - 404';
  });

  return (
    <>
      <div className="pt-16 h-screen flex items-center justify-center">
        <h1 className="text-4xl font-bold py-3">Page not found.</h1>
      </div>
    </>
  )
}

export default NotFound;
