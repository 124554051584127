const customTheme = {
    lineNumberColor: `#6272a4`,
    lineNumberBgColor: `#282a36`,
    backgroundColor: `#282a36`,
    textColor: `#f8f8f2`,
    substringColor: `#f1fa8c`,
    keywordColor: `#A78BFA`,
    attributeColor: `#50fa7b`,
    selectorTagColor: `#8be9fd`,
    docTagColor: `#f1fa8c`,
    nameColor: `#66d9ef`,
    builtInColor: `#50fa7b`,
    literalColor: `#A78BFA`,
    bulletColor: `#8BE9FD`,
    codeColor: `#50FA7B`,
    additionColor: `#f1fa8c`,
    regexpColor: `#F1FA8C`,
    symbolColor: `#F1FA8C`,
    variableColor: `#F8F8F2`,
    templateVariableColor: `#A78BFA`,
    linkColor: `#00bcd4`,
    selectorAttributeColor: `#A78BFA`,
    selectorPseudoColor: `#A78BFA`,
    typeColor: `#8BE9FD`,
    stringColor: `#F1FA8C`,
    selectorIdColor: `#50FA7B`,
    selectorClassColor: `#50FA7B`,
    quoteColor: `#E9F284`,
    templateTagColor: `#A78BFA`,
    deletionColor: `#A78BFA`,
    titleColor: `#ff555580`,
    sectionColor: `#F8F8F2`,
    commentColor: `#6272A4`,
    metaKeywordColor: `#50FA7B`,
    metaColor: `#50FA7B`,
    functionColor: `#50FA7B`,
    numberColor: `#bd93f9`,
}

export default customTheme;
