import React, { useEffect } from 'react';

const ComparingSlidesAiAndSlideSpeak = () => {

  useEffect(() => {
    document.title = 'Comparing SlidesAI and SlideSpeak for PowerPoint creation';
  }, []);

  return (
    <div className="py-16">
      <div className="container max-w-3xl mx-auto px-8">
        <h1 className="text-5xl font-bold py-3">Comparing SlidesAI and SlideSpeak</h1>
        <h2 className="text-2xl py-3">Comparison between 2 AI-powered PowerPoint creation
          tools.</h2>

        <img className="mt-5"
             src="/media/comparing-slidesai-and-slidespeak/dalle-mage.webp"
             alt="A mage generating a presentation."/>
        <p className="text-sm">Image generated using DALL·E 3 | An old wizard creating a
          presentation with magic</p>

        <h2 className="text-3xl font-bold py-3">What is currently possible with AI?</h2>
        <p>
          AI has come a long way in recent years. It is now possible to generate a PowerPoint
          presentation from scratch that looks quite professional. <a
          href="https://slidesai.io/?via=mike-van-egmond" target="_blank"
          className="underline text-blue-800">SlidesAI</a> and <a
          href="https://slidespeak.co/?via=mike" target="_blank"
          className="underline text-blue-800">SlideSpeak</a> are 2 tools that make this possible.
          These tools allow you to get a first draft of a presentation in a matter of seconds.
        </p>

        <h2 className="text-3xl font-bold py-3">A good starting point</h2>
        <p>
          From my experience working with these tools, I can say that both are a good starting point
          for a presentation. That being said you will have to make adjustments to the
          presentations. I still think that these tools save a lot of time as they allow you to get
          up and running with a presentation in about a minute.
        </p>

        <h2 className="text-3xl font-bold py-3">Using SlideSpeak</h2>

        <img className="my-5"
             src="/media/comparing-slidesai-and-slidespeak/slidespeak-homepage.webp"
             alt="The homepage of SlideSpeak."/>

        <p>
          With <a className="text-blue-800 underline" href="https://slidespeak.co/?via=mike"
                  target="_blank">SlideSpeak</a>, you can create a presentation
          based on a topic or a document.
        </p>

        <h3 className="text-2xl font-bold py-3">Creating a presentation based on a topic.</h3>

        <p>
          When you want to create a presentation from scratch you can do so by creating a
          presentation based on a topic. You first enter a short prompt that describes the topic of
          the presentation. SlideSpeak will then create an outline for you. This will form the
          general structure of the presentation and you are free to edit this. After conforming this
          structure you have some customization options like choosing the tone, the theme and adding
          some custom instructions that SlideSpeak should use hwen creating the presentation.
        </p>

        <video className="my-5" autoPlay muted loop playsInline>
          <source src="/media/comparing-slidesai-and-slidespeak/slidespeak-flow.webm"
                  type="video/webm"/>
          <source src="/media/comparing-slidesai-and-slidespeak/slidespeak-flow-compressed.mp4"
                  type="video/mp4"/>
        </video>

        <h3 className="text-2xl font-bold py-3">Creating a presentation based on a document.</h3>

        <p>
          It's also possible to create a presentation based on an uploaded document. This is a
          great way to read through and understand a large file or to get a first draft of a
          presentation started. SlideSpeak will use the information from the document to create the
          presentation for you. This can significantly streamline the process of creating a
          presentation, especially for complex or detailed documents.
        </p>

        <p className="mt-2">I explain more about about this later on in this blog post.</p>

        <h2 className="text-3xl font-bold py-3">Using SlidesAI</h2>

        <img className="my-5"
             src="/media/comparing-slidesai-and-slidespeak/slidesai-homepage.webp"
             alt="The homepage of SlidesAI."/>

        <p>
          <a className="text-blue-800 underline" href="https://slidesai.io/?via=mike-van-egmond"
             target="_blank">SlidesAI</a> takes a different approach to
          creating a presentation. SlidesAI is a plugin for
          Google Slides. This means that you can use it directly in Google Slides. If you like using
          Google Slides this might be a good option but if you prefer PowerPoint it could be a
          little cumbersome. They are working on a version that works with PowerPoint but that is
          not currently available. It is however possible to download a Google Slides presentation
          as a PowerPoint file.
        </p>

        <h3 className="text-2xl font-bold py-3">Creating a presentation based on a topic</h3>

        <p>
          Similar to SlideSpeak, SlidesAI allows you to create a presentation based on a topic. You
          can give a general description, choose the presentation type and length as well as
          customize the look of the presentation and SlidesAI does the rest.
        </p>

        <video className="my-5" autoPlay muted loop playsInline>
          <source src="/media/comparing-slidesai-and-slidespeak/slidesai-flow.webm"
                  type="video/webm"/>
          <source src="/media/comparing-slidesai-and-slidespeak/slidesai-flow-compressed.mp4"
                  type="video/mp4"/>
        </video>

        <h2 className="text-3xl font-bold py-3">How long does it take?</h2>
        <p>
          The video's above show the proces of creating a presentation from a topic but they are cut
          down to show the process quicker. In reality it takes about 1 minute to generate a
          presentation from start to finish. Both services are similar in this regard.
        </p>

        <h2 className="text-3xl font-bold py-3">Generate based on a document.</h2>
        <p>
          One of SlideSpeak’s advantages over SlidesAI is its document-based presentation generation
          feature. This allows users to convert lengthy documents into digestible presentations,
          saving considerable time and effort in summarizing content. While SlidesAI is developing
          this feature, SlideSpeak currently offers a more robust solution for document-driven
          presentation creation.
        </p>

        <img className="my-5"
             src="/media/comparing-slidesai-and-slidespeak/slidespeak-generate-based-on-file.webp"
             alt="The settings for creating a presentation based on a file."/>

        <h2 className="text-3xl font-bold py-3">Chatting with a document.</h2>
        <p>
          Another feature that SlideSpeak offers is the ability to chat with your document. This is
          a great way to get a summary of a document or to get important information from a document
          quickly. SlideSpeak uses OpenAI under the hood so the chat experience is quite good.
        </p>

        <img className="my-5" src="/media/comparing-slidesai-and-slidespeak/slidespeak-chat.webp"
             alt="The interface to chat with your document."/>

        <h2 className="text-3xl font-bold py-3">Pricing</h2>
        <p>
          The pricing options between the services are somewhat similar. Both offer a free tier
          allowing you to try the service out for free. SlideSpeak is slightly more expensive but
          also offer more features like chatting with documents.
        </p>

        <a href="https://slidespeak.co/pricing/?via=mike">
          <img className="mt-5"
               src="/media/comparing-slidesai-and-slidespeak/slidespeak-pricing.webp"
               alt="The pricing page of SlideSpeak"/>
        </a>

        <p className="mb-10 text-lg text-blue-800 underline">
          <a href="https://slidespeak.co/pricing/?via=mike">The pricing page of SlideSpeak</a>
        </p>


        <a href="https://www.slidesai.io/pricing?via=mike-van-egmond">
          <img className="ty-5"
               src="/media/comparing-slidesai-and-slidespeak/slidesai-pricing.webp"
               alt="The pricing page of SlidesAI"/>

        </a>

        <p className="mb-10 text-lg text-blue-800 underline">
          <a href="https://www.slidesai.io/pricing?via=mike-van-egmond">The pricing page of
            SlidesAI</a>
        </p>

        <h3 className="text-xl font-bold my-4 text-blue-800 underline">
          <a href="https://slidespeak.co/?via=mike" target="_blank">
            SlideSpeak website
          </a>
        </h3>

        <h3 className="text-xl font-bold text-blue-800 underline">
          <a href="https://slidesai.io/?via=mike-van-egmond" target="_blank">
            SlidesAi website
          </a>
        </h3>

      </div>
    </div>
  )

}

export default ComparingSlidesAiAndSlideSpeak;
