import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Home from "./pages/home.jsx";
import Nav from "./components/partials/nav.jsx";
import VueComponentToNpmPackage from "./pages/vue-component-to-npm-package.jsx";
import VueComponentToNpmPackageOld from "./pages/vue-component-to-npm-package-old.jsx";
import SimpleContinuousDeploymentDocker from './pages/simple-continuous-deployment-docker';
import ComparingSlidesAiAndSlideSpeak from './pages/comparing-slidesai-and-slidespeak';
import ScrollToTop from './components/partials/scrollToTop';
import NotFound from './components/partials/not-found';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop/>
      <Nav/>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/vue-component-to-npm-package-old" element={<VueComponentToNpmPackageOld/>}/>
        <Route path="/vue-component-to-npm-package" element={<VueComponentToNpmPackage/>}/>
        <Route path="/simple-continuous-deployment-docker" element={<SimpleContinuousDeploymentDocker/>}/>
        <Route path="/comparing-slidesai-and-slidespeak" element={<ComparingSlidesAiAndSlideSpeak/>}/>
        <Route path="*" element={<NotFound/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
