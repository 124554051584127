import React from 'react';
import Card from '../components/partials/card';

const Home = () => {
  return (
    <div className="container mx-auto max-w-xl py-32">
      <Card
        title="Comparing SlidesAI and SlideSpeak for PowerPoint creation"
        snippet="Comparison between 2 AI-powered PowerPoint creation tools."
        url="/comparing-slidesai-and-slidespeak"
      />
      <Card
        title="Publishing a Vue 3 component on npm"
        snippet="Using Vite's Library Mode to easily publish a Vue 3 component."
        url="/vue-component-to-npm-package"
      />
      <Card
        title="Simple Continuous Deployment for Docker"
        snippet="Using Watchtower to automatically update your containers."
        url="/simple-continuous-deployment-docker"
      />
    </div>
  )
}

export default Home;
