import React, { useEffect } from 'react';
import { CopyBlock } from "react-code-blocks";
import customTheme from "../customTheme";

const codeBlock = ({ code, language, showLineNumbers }) => {
  return (
    <CopyBlock
      text={code}
      language={language}
      showLineNumbers={showLineNumbers}
      theme={customTheme}
      codeBlock
    />
  );
}

const SimpleContinuousDeploymentDocker = () => {

  useEffect(() => {
    document.title = 'Simple Continuous Deployment for Docker';
  }, []);

  return (
    <div className="py-16">
      <div className="container max-w-3xl mx-auto px-8">
        <h1 className="text-5xl font-bold py-3">Simple Continuous Deployment for Docker</h1>
        <h2 className="text-2xl py-3">Using Watchtower to automatically update your containers.</h2>

        <h2 className="text-3xl font-bold py-3">How it works</h2>
        <p>
          <a href="https://github.com/containrrr/watchtower/" target="_blank" rel="noreferrer"
             className="underline text-blue-800">Watchtower</a> can watch for changes of an image in a container
          registry. When this image is
          updated it will delete the old container and start a new one with the latest image. This,
          combined with a GitHub action to automatically build and upload a docker image to the
          container registry we can easily set up a simple continuous deployment pipeline.
        </p>

        <img className="hidden md:block my-5" src="/media/watchtower-flow.png"
             alt="The flow of updates using Watchtower."/>
        <img className="md:hidden my-5" src="/media/watchtower-flow-mobile.png"
             alt="The flow of updates using Watchtower."/>

        <h2 className="text-3xl font-bold py-3">Requirements</h2>
        <p>
          For this setup you will need:
          <ul className="list-disc ml-4">
            <li>Any place to store your git repo, im using GitHub.</li>
            <li>A way of creating pipelines, im using GitHub Actions.</li>
            <li>Container Registry, im using GitHub Container Registry.</li>
            <li>A VPS to deploy the project. Im using Digital Ocean, you can use my referral code
              to get 200 dollars in free credit for 60 days.
            </li>
          </ul>
        </p>

        <div className="my-4">
          <a
            href="https://www.digitalocean.com/?refcode=51961e386402&utm_campaign=Referral_Invite&utm_medium=Referral_Program&utm_source=badge">
            <img src="https://web-platforms.sfo2.digitaloceanspaces.com/WWW/Badge%203.svg"
                 alt="DigitalOcean Referral Badge"/>
          </a>
        </div>

        <p>
          But the great thing about this setup is that you can use basically any service you like
          because Watchtower only needs to be able to access the container registry.
        </p>


        <h2 className="text-3xl font-bold py-3">Setting up the project</h2>
        <p>
          Lets first set up a test project. Im using a simple Node.js project with Express.js. If
          you
          dont want to setup the code yourself you can clone the repo from <a
          href="https://github.com/mve/watchtower-cd" target="_blank" rel="noreferrer" className="underline text-blue-800">
          my GitHub repo</a>.
        </p>

        <p>
          we'll set up a simple express.js server and use an environment variable for the port.
          This is the package.json file.
        </p>

        <div className="my-2">
          {codeBlock({
            code: '{\n' +
              '  "dependencies": {\n' +
              '    "dotenv": "^16.0.3",\n' +
              '    "express": "^4.18.2"\n' +
              '  },\n' +
              '  "type": "module"\n' +
              '}\n', language: 'json', showLineNumbers: true
          })}
        </div>

        <p>
          Next, we can create the basic setup for the express.js API. All files that we want inside the
          container will be placed inside the src folder.
        </p>

        <p>
          in src/app.js, we can add the following content:
        </p>

        <div className="my-2">
          {codeBlock({
            code: 'import dotenv from "dotenv";\n' +
              'import express from "express";\n' +
              '\n' +
              'dotenv.config();\n' +
              'const { PORT } = process.env;\n' +
              '\n' +
              'const app = express();\n' +
              '\n' +
              'app.get(\'/\', (req, res) => {\n' +
              '  res.send(\'Hello World!\');\n' +
              '});\n' +
              '\n' +
              'app.listen(PORT, () => {\n' +
              '  console.log(`Example app listening on port ${PORT}`);\n' +
              '});\n', language: 'javascript', showLineNumbers: true
          })}
        </div>

        <p>
          Now when you run node src/app.js you should see the message "Example app listening on port
          3000"
          To test the API you can send a GET request to localhost:3000 using curl or Postman.
        </p>

        <div className="my-2">
          {codeBlock({
            code: 'curl http://localhost:3000\n' +
              'Hello World!', language: 'bash', showLineNumbers: true
          })}
        </div>

        <p>
          now that the basic project is done we can create the Dockerfile.
          Create a file called "Dockerfile" in the root of the project and add the following content:
        </p>

        <div className="my-2">
          {codeBlock({
            code: 'FROM node:20-alpine\n' +
              '\n' +
              'WORKDIR /app\n' +
              '\n' +
              'COPY package*.json ./\n' +
              '\n' +
              'RUN npm install\n' +
              '\n' +
              'COPY src .\n' +
              '\n' +
              'EXPOSE 3000\n' +
              '\n' +
              'CMD [ "node", "app.js" ]\n', language: 'Dockerfile', showLineNumbers: true
          })}
        </div>

        <p>
          This dockerfile will copy all files from the src directory, expose port 3000 and run the
          app.js file with Node.js.
        </p>

        <p>
          I like to use a docker-compose.yaml file to deploy the app so we can create that now as
          well. In a later step we will use this file to deploy the app to the VPS.
        </p>

        <div className="my-2">
          {codeBlock({
            code: 'version: "3.8"\n' +
              'services:\n' +
              '  express:\n' +
              '    image: ghcr.io/mve/watchtower-cd:main\n' +
              '    environment:\n' +
              '      - PORT=${PORT}\n' +
              '    restart: always\n' +
              '    ports:\n' +
              '      - "3000:3000"\n', language: 'yaml', showLineNumbers: true
          })}
        </div>

        <p>I've also added a .env file in the root of the project where I set the port.</p>

        <div className="my-2">
          {codeBlock({
            code: 'PORT=3000', language: 'env', showLineNumbers: true
          })}
        </div>

        <p>
          And a .gitignore to ignore some files we dont have to commit.
        </p>

        <div className="my-2">
          {codeBlock({
            code: 'node_modules\n' +
              '.env\n' +
              '.idea\n', language: 'gitignore', showLineNumbers: true
          })}
        </div>

        <h2 className="text-3xl font-bold py-3">Uploading to the registry</h2>
        <p>
          Now we can create the GitHub Action to upload to the GitHub Container Registry. I just use
          the example docker-publish from GitHub, the only change I made was to change the trigger
          to only run on push to main.
        </p>

        <p>The file is stored in .github/workflows/docker-publish.yml</p>

        <div className="my-2">
          {codeBlock({
            code: 'name: Docker\n' +
              '\n' +
              '# This workflow uses actions that are not certified by GitHub.\n' +
              '# They are provided by a third-party and are governed by\n' +
              '# separate terms of service, privacy policy, and support\n' +
              '# documentation.\n' +
              '\n' +
              'on:\n' +
              '  push:\n' +
              '    branches: [ "main" ]\n' +
              '\n' +
              'env:\n' +
              '  # Use docker.io for Docker Hub if empty\n' +
              '  REGISTRY: ghcr.io\n' +
              '  # github.repository as <account>/<repo>\n' +
              '  IMAGE_NAME: ${{ github.repository }}\n' +
              '\n' +
              '\n' +
              'jobs:\n' +
              '  build:\n' +
              '\n' +
              '    runs-on: ubuntu-latest\n' +
              '    permissions:\n' +
              '      contents: read\n' +
              '      packages: write\n' +
              '      # This is used to complete the identity challenge\n' +
              '      # with sigstore/fulcio when running outside of PRs.\n' +
              '      id-token: write\n' +
              '\n' +
              '    steps:\n' +
              '      - name: Checkout repository\n' +
              '        uses: actions/checkout@v3\n' +
              '\n' +
              '      # Install the cosign tool except on PR\n' +
              '      # https://github.com/sigstore/cosign-installer\n' +
              '      - name: Install cosign\n' +
              '        if: github.event_name != \'pull_request\'\n' +
              '        uses: sigstore/cosign-installer@f3c664df7af409cb4873aa5068053ba9d61a57b6 #v2.6.0\n' +
              '        with:\n' +
              '          cosign-release: \'v1.13.1\'\n' +
              '\n' +
              '\n' +
              '      # Workaround: https://github.com/docker/build-push-action/issues/461\n' +
              '      - name: Setup Docker buildx\n' +
              '        uses: docker/setup-buildx-action@79abd3f86f79a9d68a23c75a09a9a85889262adf\n' +
              '\n' +
              '      # Login against a Docker registry except on PR\n' +
              '      # https://github.com/docker/login-action\n' +
              '      - name: Log into registry ${{ env.REGISTRY }}\n' +
              '        if: github.event_name != \'pull_request\'\n' +
              '        uses: docker/login-action@28218f9b04b4f3f62068d7b6ce6ca5b26e35336c\n' +
              '        with:\n' +
              '          registry: ${{ env.REGISTRY }}\n' +
              '          username: ${{ github.actor }}\n' +
              '          password: ${{ secrets.GITHUB_TOKEN }}\n' +
              '\n' +
              '      # Extract metadata (tags, labels) for Docker\n' +
              '      # https://github.com/docker/metadata-action\n' +
              '      - name: Extract Docker metadata\n' +
              '        id: meta\n' +
              '        uses: docker/metadata-action@98669ae865ea3cffbcbaa878cf57c20bbf1c6c38\n' +
              '        with:\n' +
              '          images: ${{ env.REGISTRY }}/${{ env.IMAGE_NAME }}\n' +
              '\n' +
              '      # Build and push Docker image with Buildx (don\'t push on PR)\n' +
              '      # https://github.com/docker/build-push-action\n' +
              '      - name: Build and push Docker image\n' +
              '        id: build-and-push\n' +
              '        uses: docker/build-push-action@ac9327eae2b366085ac7f6a2d02df8aa8ead720a\n' +
              '        with:\n' +
              '          context: .\n' +
              '          push: ${{ github.event_name != \'pull_request\' }}\n' +
              '          tags: ${{ steps.meta.outputs.tags }}\n' +
              '          labels: ${{ steps.meta.outputs.labels }}\n' +
              '          cache-from: type=gha\n' +
              '          cache-to: type=gha,mode=max\n' +
              '\n' +
              '\n' +
              '      # Sign the resulting Docker image digest except on PRs.\n' +
              '      # This will only write to the public Rekor transparency log when the Docker\n' +
              '      # repository is public to avoid leaking data.  If you would like to publish\n' +
              '      # transparency data even for private images, pass --force to cosign below.\n' +
              '      # https://github.com/sigstore/cosign\n' +
              '      - name: Sign the published Docker image\n' +
              '        if: ${{ github.event_name != \'pull_request\' }}\n' +
              '        env:\n' +
              '          COSIGN_EXPERIMENTAL: "true"\n' +
              '        # This step uses the identity token to provision an ephemeral certificate\n' +
              '        # against the sigstore community Fulcio instance.\n' +
              '        run: echo "${{ steps.meta.outputs.tags }}" | xargs -I {} cosign sign {}@${{ steps.build-and-push.outputs.digest }}\n', language: 'yaml', showLineNumbers: true
          })}
        </div>

        <p>
          Now when you commit to the main branch, the workflow will run and build a new Docker
          image, push it to the container registry, and sign it with cosign. We are now ready to
          set up Watchtower to watch for changes in the container registry.
        </p>

        <h2 className="text-3xl font-bold py-3">Setting up the VPS</h2>
        <p>
          Before we get started with Watchtower we have to get Docker installed on the VPS.
          I'm using a DigitalOcean droplet with Ubuntu 22.04 (LTS).
        </p>

        <img src="/media/digital-ocean-droplet-creation.png" alt="Digital Ocean VPS creation screen."/>

        <p>
          Once the droplet is created, I'll SSH into it and install Docker.
        </p>

        <div className="my-2">
          {codeBlock({
            code: 'sudo apt update', language: 'bash', showLineNumbers: true
          })}
        </div>

        <div className="my-2">
          {codeBlock({
            code: 'sudo apt install apt-transport-https ca-certificates curl software-properties-common', language: 'bash', showLineNumbers: true
          })}
        </div>

        <div className="my-2">
          {codeBlock({
            code: 'curl -fsSL https://download.docker.com/linux/ubuntu/gpg | sudo gpg --dearmor -o /usr/share/keyrings/docker-archive-keyring.gpg', language: 'bash', showLineNumbers: true
          })}
        </div>

        <div className="my-2">
          {codeBlock({
            code: 'echo "deb [arch=$(dpkg --print-architecture) signed-by=/usr/share/keyrings/docker-archive-keyring.gpg] https://download.docker.com/linux/ubuntu $(lsb_release -cs) stable" | sudo tee /etc/apt/sources.list.d/docker.list > /dev/null', language: 'bash', showLineNumbers: true
          })}
        </div>

        <div className="my-2">
          {codeBlock({
            code: 'sudo apt update', language: 'bash', showLineNumbers: true
          })}
        </div>

        <div className="my-2">
          {codeBlock({
            code: 'apt-cache policy docker-ce', language: 'bash', showLineNumbers: true
          })}
        </div>

        <p>
          If you want to learn more about these commands i recommend checking out this <a
          href="https://www.digitalocean.com/community/tutorials/how-to-install-and-use-docker-on-ubuntu-22-04#step-1-installing-docker"
          className="underline text-blue-800" target="_blank" rel="noreferrer">
          blog post from DigitalOcean</a>.
        </p>

        <p>
          To verify you have Docker installed run docker -v. You should see something like this:
        </p>

        <div className="my-2">
          {codeBlock({
            code: 'docker -v\n' +
              'Docker version 23.0.5, build bc4487a', language: 'bash', showLineNumbers: true
          })}
        </div>

        <h2 className="text-3xl font-bold py-3">Setting up Watchtower</h2>
        <p>
          Now we can set up Watchtower to watch for changes in the container registry. I prefer using
          the docker-compose file to run Watchtower. Create a folder for Watchtower on the VPS and create
          a docker-compose.yml file with the following content:
        </p>

        <div className="my-2">
          {codeBlock({
            code: 'version: "3"\n' +
              'services:\n' +
              '  watchtower:\n' +
              '    image: containrrr/watchtower\n' +
              '    volumes:\n' +
              '      - /var/run/docker.sock:/var/run/docker.sock\n' +
              '      - /root/.docker/config.json:/config.json\n' +
              '    command: --interval 30', language: 'yaml', showLineNumbers: true
          })}
        </div>

        <p>
          Now we have to authenticate with the registry.
          Create an access token in github with the following scope:
        </p>

        <ul className="list-disc ml-4">
          <li>read:packages</li>
        </ul>

        <p>
          you can find the access token settings in your GitHub profile under developer settings,
          or click <a href="https://github.com/settings/tokens" target="_blank" rel="noreferrer"
                      className="underline text-blue-800">this link</a>.
        </p>

        <div className="my-2">
          {codeBlock({
            code: 'export CR_PAT=TOKEN_HERE\n' +
              'docker login ghcr.io -u USERNAME_HERE --password-stdin', language: 'yaml', showLineNumbers: true
          })}
        </div>

        <p>
          After that a file is created in /root/.docker/config.json with the following content:
        </p>

        <div className="my-2">
          {codeBlock({
            code: '{\n' +
              '        "auths": {\n' +
              '                "ghcr.io": {\n' +
              '                        "auth": "TOKEN_HERE"\n' +
              '                }\n' +
              '        }\n' +
              '}  ', language: 'yaml', showLineNumbers: true
          })}
        </div>

        <p>
          When that is all done, you can start the Watchtower container with docker compose up -d.
        </p>

        <h2 className="text-3xl font-bold py-3">Starting the express project</h2>

        <p>
          First create a docker-compose.yaml file with the content we created earlier. We are not going to
          clone the project to the VPS as this is not necessary. All the code needed to run the project
          is stored inside the docker container, which we can now access because we have logged in to
          the private container registry.
        </p>

        <div className="my-2">
          {codeBlock({
            code: 'version: "3.8"\n' +
              'services:\n' +
              '  express:\n' +
              '    image: ghcr.io/mve/watchtower-cd:main\n' +
              '    environment:\n' +
              '      - PORT=${PORT}\n' +
              '    restart: always\n' +
              '    ports:\n' +
              '      - "3000:3000"', language: 'yaml', showLineNumbers: true
          })}
        </div>

        <p>
          Then create a .env file with the PORT=3000 variable.
          After that you can start the project with docker compose up -d.
          And verify it works by running curl with the ipv4 address of the VPS, followed by the port.
        </p>

        <div className="my-2">
          {codeBlock({
            code: 'curl 123.345.56.789:3000\n' +
              'Hello World!% ', language: 'yaml', showLineNumbers: true
          })}
        </div>

        <p>
          We are now ready to verify that everything is working. First, lets make a change to the project.
          We can update the hello world message in the index.js file to something else.
        </p>

        <div className="my-2">
          {codeBlock({
            code: 'app.get(\'/\', (req, res) => {\n' +
              '  res.send(\'Hello World Updated!\');\n' +
              '});', language: 'yaml', showLineNumbers: true
          })}
        </div>

        <p>
          We can now commit this change and push it to GitHub. This will trigger the pipeline that
          we created earlier. After this is finished we can verify that the container has been
          updated by running curl again. You should keep in mind that this update can take about
          a minute to complete. The reason for this is that Watchtower checks for updates every 30
          seconds, and this process only starts when the GitHub pipeline has finished.
        </p>

        <div className="my-2">
          {codeBlock({
            code: 'curl 123.345.56.789:3000\n' +
              'Hello World Updated!% ', language: 'yaml', showLineNumbers: true
          })}
        </div>

        <p>
          And that's it, your deployment will be automatically updated when a commit is made on the main branch!
        </p>

        <h2 className="text-3xl font-bold py-3">Good to know</h2>
        <p>
          Watchtower logs every update it makes. you can check these logs by running docker logs watchtower-watchtower-1.
          The output of the update looks like this:
        </p>

        <div className="my-2">
          {codeBlock({
            code: 'docker logs watchtower-watchtower-1\n' +
              'time="2023-05-03T14:24:36Z" level=info msg="Session done" Failed=0 Scanned=2 Updated=0 notify=no\n' +
              'time="2023-05-03T14:25:06Z" level=info msg="Session done" Failed=0 Scanned=2 Updated=0 notify=no\n' +
              'time="2023-05-03T14:25:37Z" level=info msg="Found new ghcr.io/mve/watchtower-cd:main image (ba0012d4fcd3)"\n' +
              'time="2023-05-03T14:25:38Z" level=info msg="Stopping /watchtower-cd-express-1 (118c73fb1bb0) with SIGTERM"\n' +
              'time="2023-05-03T14:25:48Z" level=info msg="Creating /watchtower-cd-express-1"\n' +
              'time="2023-05-03T14:25:48Z" level=info msg="Session done" Failed=0 Scanned=2 Updated=1 notify=no', language: 'yaml', showLineNumbers: true
          })}
        </div>

        <h3 className="text-xl font-bold mt-4 text-blue-800 underline"><a
          href="https://github.com/mve/watchtower-cd" target="_blank" rel="noreferrer">GitHub code</a></h3>
        <h3 className="text-xl font-bold text-blue-800 underline"><a
          href="https://containrrr.dev/watchtower/" target="_blank" rel="noreferrer">Watchtower Docs</a></h3>

      </div>
    </div>
  )

}

export default SimpleContinuousDeploymentDocker;
