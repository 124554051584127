const card = ({ title, snippet, url }) => {
  return (
    <div className="mb-5">
      <a href={url}>
        <div className="hover-effect rounded-xl bg-white shadow-xl p-5">
          <h3 className="text-md tracking-tight font-bold text-gray-800 sm:text-lg md:text-xl mb-3">
            {title}
          </h3>
          <p>{snippet}</p>
        </div>
      </a>
    </div>

  )
}

export default card;
