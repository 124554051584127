import React, { useEffect } from 'react';
import { CopyBlock } from "react-code-blocks";
import customTheme from "../customTheme";

const codeBlock = ({ code, language, showLineNumbers }) => {
  return (
    <CopyBlock
      text={code}
      language={language}
      showLineNumbers={showLineNumbers}
      theme={customTheme}
      codeBlock
    />
  );
}

const VueComponentToNpmPackageOld = () => {

  useEffect(() => {
    document.title = 'Publishing a Vue 3 component on npm';
  }, []);

  return (
    <div className="py-16">
      <div className="container max-w-3xl mx-auto px-8">
        <h1 className="text-5xl font-bold py-3">Publishing a Vue 3 component on npm</h1>
        <h2 className="text-2xl py-3">Using vue-sfc-rollup to easily publish a vue 3 component.</h2>

        <h2 className="text-3xl font-bold py-3">Setting up the project</h2>
        <p>Example component code</p>

        <div className="my-2">
          {codeBlock({
            code: '<template>\n' +
              '  <button>{{ text }}</button>\n' +
              '</template>\n' +
              '\n' +
              '<script>\n' +
              'export default {\n' +
              '  name: \'VueComponentNpmExample\',\n' +
              '  props: {\n' +
              '    text: {type: String, default: "Default text"}\n' +
              '  }\n' +
              '}\n' +
              '</script>\n', language: 'jsx', showLineNumbers: true
          })}
        </div>

        <p>
          Next, install the <a href="https://www.npmjs.com/package/vue-sfc-rollup"
                               className="text-blue-800 underline font-bold">vue-sfc-rollup</a> package
          globally. We
          will use this to scaffold a minimal setup ready to be published to npm.
        </p>

        <div className="my-2">
          {codeBlock({
            code: 'npm install -g vue-sfc-rollup', language: 'jsx', showLineNumbers: true
          })}
        </div>

        <p>After that, run sfc-init from the root of your project to generate the template.</p>

        <div className="my-2">
          {codeBlock({
            code: 'sfc-init', language: 'jsx', showLineNumbers: true
          })}
        </div>

        <p>Then just follow the steps, but make sure you choose a <span className="font-bold">unique name</span> for
          your package that does not yet exist on npm. In this example I used the following
          settings:</p>
        <ul className="list-disc list-inside">
          {/* Which version of vue are you writing for? */}
          <li>Vue 3</li>
          {/* Is this a single component or a library? */}
          <li>Single Component</li>
          {/* What is the npm name of your component? */}
          <li>vue-component-npm-example</li>
          {/* What is the kebab-case tag name of your component? */}
          <li>vue-component-npm-example</li>
          {/* Will this component be written in JavaScript or TypeScript? */}
          <li>JavaScript</li>
          {/* Enter a location to save the component files: */}
          <li>./vue-component-npm-example</li>

          {/* Init is complete, your files have been generated and saved into the directory you specified above.
                            Within that directory, use src/test.vue as a starting point for your SFC.
                            When you're ready, run `npm run build` to generate the redistributable versions. */}
        </ul>

        <br/>

        <p>Navigate to the newly created folder and install the packages.</p>

        <div className="my-2">
          {codeBlock({
            code: 'cd <package-name>\n' +
              'npm install', language: '', showLineNumbers: true
          })}
        </div>

        <p>Copy the content from your component
          into &lt;package-name&gt;/src/&lt;package-name&gt;.vue If you want to test the package you
          can also update the &lt;package-name&gt;/dev/serve.vue file.</p>

        <div className="my-2">
          {codeBlock({
            code: '<script>\n' +
              'import { defineComponent } from \'vue\';\n' +
              'import VueComponentNpmExample from \'@/vue-component-npm-example.vue\';\n' +
              '\n' +
              'export default defineComponent({\n' +
              '  name: \'ServeDev\',\n' +
              '  components: {\n' +
              '    VueComponentNpmExample\n' +
              '  }\n' +
              '});\n' +
              '</script>\n' +
              '\n' +
              '<template>\n' +
              '  <div id="app">\n' +
              '    <vue-component-npm-example text="Example button" />\n' +
              '  </div>\n' +
              '</template>\n', language: 'jsx', showLineNumbers: true
          })}
        </div>

        <p>You can now test your component by running npm run serve.</p>

        <h2 className="text-3xl font-bold py-3">Publishing the component on npm</h2>
        <p>Any time you publish a new version you need to make sure to update the version in the
          package.json file. You can read more about this <a
            href="https://docs.npmjs.com/about-semantic-versioning"
            className="text-blue-800 underline font-bold">here</a>. for this first version it's fine
          to leave it at 1.0.0.</p>

        <p>Next, run the build command.</p>

        <div className="my-2">
          {codeBlock({
            code: 'npm run build'
          })}
        </div>

        <p>Before you can publish your package you need to be logged in to your npm account. If you
          don't have an account yet, make one first. If you have an account you can use npm adduser
          to log in.</p>

        <div className="my-2">
          {codeBlock({
            code: 'npm adduser'
          })}
        </div>

        <p>The final step is publishing the package.</p>

        <div className="my-2">
          {codeBlock({
            code: 'npm publish'
          })}
        </div>

        <p>Check if the project successfully published on npm. To do this click on your pfp on <a
          href="https://npmjs.com" className="text-blue-800 underline font-bold">npm</a> and then go
          to packages. it should be there.</p>

        <p>You can now install it from npm and use it in a project!</p>

        <h3 className="text-xl font-bold mt-4 text-blue-800 underline"><a
          href="https://github.com/mve/vue-component-npm-example">GitHub code</a></h3>
        <h3 className="text-xl font-bold text-blue-800 underline"><a
          href="https://www.npmjs.com/package/vue-component-npm-example">npm package</a></h3>

      </div>
    </div>
  )

}

export default VueComponentToNpmPackageOld;
