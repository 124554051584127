import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faAt } from '@fortawesome/free-solid-svg-icons';

const Nav = () => (
  <nav className="fixed z-50 w-full h-16 shadow-lg" style={{ backdropFilter: "blur(30px)" }}>

    <div className="h-full container mx-auto flex items-center justify-between">
      <div>
        <Link className="flex items-center" to={"/"}>
          <img src="/media/logo/logo.svg" className="h-10 ml-3" alt=""/>
          <h3 className="text-xl font-extrabold tracking-tight ml-2">Egmond.dev</h3>
        </Link>
      </div>

      <div className="flex flex-row">
        <a name="GitHub" href="https://github.com/mve" className="mr-4 hover-effect">
          <div className="bg-purple-200 rounded-lg p-2">
            <FontAwesomeIcon icon={faGithub} className="text-3xl text-purple-900"/>
          </div>
        </a>
        <a name="LinkedIn" href="https://www.linkedin.com/in/mike-van-egmond/"
           className="mr-4 hover-effect">
          <div className="bg-purple-200 rounded-lg p-2">
            <FontAwesomeIcon icon={faLinkedin} className="text-3xl text-purple-900"/>
          </div>
        </a>
        <a name="Email" href="mailto:mikevanegmond1998@gmail.com"
           className="mr-4 hover-effect hidden xs:block">
          <div className="bg-purple-200 rounded-lg p-2">
            <FontAwesomeIcon icon={faAt} className="text-3xl text-purple-900"/>
          </div>
        </a>
      </div>

    </div>


  </nav>
)

export default Nav;
