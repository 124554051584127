import React, { useEffect } from 'react';
import { CopyBlock } from "react-code-blocks";
import customTheme from "../customTheme";

const codeBlock = ({ code, language, showLineNumbers }) => {
  return (
    <CopyBlock
      text={code}
      language={language}
      showLineNumbers={showLineNumbers}
      theme={customTheme}
      codeBlock
    />
  );
}

const VueComponentToNpmPackage = () => {

  useEffect(() => {
    document.title = 'Publishing a Vue 3 component on npm';
  }, []);

  return (
    <div className="py-16">
      <div className="container max-w-3xl mx-auto px-8">
        <h1 className="text-5xl font-bold py-3">Publishing a Vue 3 component on npm</h1>
        <h2 className="text-2xl py-3">Using Vite's Library Mode to easily publish a Vue 3
          component.</h2>

        <h2 className="text-3xl font-bold py-3">Setting up the project</h2>
        <p>First we need to create a project using Vite. Use npm create vite@latest to set up the
          project. You will be prompted for a project name, a framework and a variant. You can
          choose TypeScript or JavaScript.</p>

        <div className="my-2">
          {codeBlock({
            code: 'npm create vite@latest\n' +
              '\n' +
              'Need to install the following packages:\n' +
              'create-vite@3.1.0\n' +
              'Ok to proceed? (y) y\n' +
              'Project name: vue-component-npm-example\n' +
              'Select a framework: Vue\n' +
              'Select a variant: TypeScript', language: 'jsx', showLineNumbers: true
          })}
        </div>

        <p>
          You now have a basic Vite project. Navigate to the project folder and install the
          dependencies.
        </p>

        <div className="my-2">
          {codeBlock({
            code: 'npm install\n' +
              'npm run dev', language: 'jsx', showLineNumbers: true
          })}
        </div>

        <h2 className="text-3xl font-bold py-3">Vite Library mode</h2>

        <p>
          Now we have to make some changes to vite.config.ts. To make this work we are using Vite's
          library mode.
          Change your vite.config.ts to the following:
        </p>

        <div className="my-2">
          {codeBlock({
            code: 'import { resolve } from \'path\'\n' +
              'import { defineConfig } from \'vite\'\n' +
              'import vue from \'@vitejs/plugin-vue\'\n' +
              '\n' +
              '// https://vitejs.dev/config/\n' +
              'export default defineConfig({\n' +
              '  plugins: [vue()],\n' +
              '  build: {\n' +
              '    lib: {\n' +
              '      entry: resolve(__dirname, \'lib/main.ts\'),\n' +
              '      name: \'VueComponentNpmExample\',\n' +
              '      // the proper extensions will be added\n' +
              '      fileName: \'vue-component-npm-example\'\n' +
              '    },\n' +
              '    rollupOptions: {\n' +
              '      // make sure to externalize deps that shouldn\'t be bundled\n' +
              '      // into your library\n' +
              '      external: [\'vue\'],\n' +
              '      output: {\n' +
              '        // Provide global variables to use in the UMD build\n' +
              '        // for externalized deps\n' +
              '        globals: {\n' +
              '          vue: \'Vue\'\n' +
              '        }\n' +
              '      }\n' +
              '    }\n' +
              '  }\n' +
              '})\n'
            , language: 'jsx', showLineNumbers: true
          })}
        </div>

        <p>
          If you are using TypeScript you will get a warning about the resolve function. In order to
          fix this you have to install the node types.
        </p>

        <div className="my-2">
          {codeBlock({
            code: 'npm i --save-dev @types/node'
            , language: 'jsx', showLineNumbers: true
          })}
        </div>

        <p>
          The next step is to add a new folder called lib. In this folder we will add our component.
          Inside this folder we also have to add a main.ts file. This is the entry point for our
          component.
        </p>

        <div className="my-2">
          {codeBlock({
            code: 'import VueComponentNpmExample from \'./VueComponentNpmExample.vue\'\n' +
              'export { VueComponentNpmExample }\n', language: 'ts', showLineNumbers: true
          })}
        </div>

        <p>
          Now we can create our component. Create a new vue file and create your component. For this
          example I just created a basic component.
        </p>

        <div className="my-2">
          {codeBlock({
            code: '<script setup lang="ts">\n' +
              'import { ref } from \'vue\'\n' +
              '\n' +
              'defineProps({\n' +
              '      msg: { type: String }\n' +
              '    }\n' +
              ')\n' +
              '\n' +
              'const count = ref(0)\n' +
              '</script>\n' +
              '\n' +
              '<template>\n' +
              '  <h1>{{ msg }}</h1>\n' +
              '\n' +
              '  <div class="card">\n' +
              '    <button type="button" @click="count++">count is {{ count }}</button>\n' +
              '  </div>\n' +
              '</template>\n' +
              '\n' +
              '<style scoped>\n' +
              'h1 {\n' +
              '  color: #7e22ce;\n' +
              '}\n' +
              '</style>\n'
            , language: 'jsx', showLineNumbers: true
          })}
        </div>

        <p>
          You can test your component by importing it in the App.vue file inside the src folder.
        </p>

        <div className="my-2">
          {codeBlock({
            code: '<script setup lang="ts">\n' +
              '  import VueComponentNpmExample from \'../lib/VueComponentNpmExample.vue\'\n' +
              '</script>'
            , language: 'jsx', showLineNumbers: true
          })}
        </div>

        <h2 className="text-3xl font-bold py-3">Building the component</h2>

        <p>
          When you are happy with your component we can build it. but before that we need to make
          some changes to package.json. Here we have to specify where the main file is located, as
          well as update the build command.
        </p>

        <div className="my-2">
          {codeBlock({
            code: '{\n' +
              '  "name": "vue-component-npm-example",\n' +
              '  "version": "2.0.0",\n' +
              '  "type": "module",\n' +
              '  "files": ["dist"],\n' +
              '  "main": "./dist/vue-component-npm-example.umd.cjs",\n' +
              '  "module": "./dist/vue-component-npm-example.js",\n' +
              '  "types": "./dist/main.d.ts",\n' +
              '  "exports": {\n' +
              '    ".": {\n' +
              '      "import": "./dist/vue-component-npm-example.js",\n' +
              '      "require": "./dist/vue-component-npm-example.umd.js"\n' +
              '    },\n' +
              '    "./dist/style.css": "./dist/style.css"\n' +
              '  },\n' +
              '  "scripts": {\n' +
              '    "dev": "vite",\n' +
              '    "build": "vite build && vue-tsc --emitDeclarationOnly",\n' +
              '    "preview": "vite preview"\n' +
              '  },\n' +
              '  "dependencies": {\n' +
              '    "vue": "^3.2.37"\n' +
              '  },\n' +
              '  "devDependencies": {\n' +
              '    "@types/node": "^18.7.18",\n' +
              '    "@vitejs/plugin-vue": "^3.1.0",\n' +
              '    "typescript": "^4.6.4",\n' +
              '    "vite": "^3.1.0",\n' +
              '    "vue-tsc": "^0.40.4"\n' +
              '  }\n' +
              '}\n'
            , language: 'json', showLineNumbers: true
          })}
        </div>

        <p>
          We also need to make some changes to the tsconfig.json.
          Update tsconfig.json to the following:
        </p>

        {codeBlock({
          code: '{\n' +
            '  "compilerOptions": {\n' +
            '    "target": "ESNext",\n' +
            '    "useDefineForClassFields": true,\n' +
            '    "module": "ESNext",\n' +
            '    "moduleResolution": "Node",\n' +
            '    "strict": true,\n' +
            '    "jsx": "preserve",\n' +
            '    "sourceMap": true,\n' +
            '    "resolveJsonModule": true,\n' +
            '    "isolatedModules": true,\n' +
            '    "esModuleInterop": true,\n' +
            '    "lib": ["ESNext", "DOM"],\n' +
            '    "skipLibCheck": true,\n' +
            '    "outDir": "dist",\n' +
            '    "declaration": true\n' +
            '  },\n' +
            '  "include": ["lib/**/*.ts", "lib/**/*.d.ts", "lib/**/*.tsx", "lib/**/*.vue"],\n' +
            '  "references": [{ "path": "./tsconfig.node.json" }],\n' +
            '}\n'
          , language: 'json', showLineNumbers: true
        })}

        <p>
          You might have to run npm install again. After that we are ready to build our component.
          Run the following command to build your
          component:
        </p>

        <div className="my-2">
          {codeBlock({
            code: 'npm run build'
            , language: 'jsx', showLineNumbers: true
          })}
        </div>

        <p>
          You should now see a dist folder in your project. Inside this folder you should see some
          js files, a css file and types. The css file is created from any scoped style you added in
          your component. The types are created by vue-tsc.
        </p>

        <h2 className="text-3xl font-bold py-3">Testing the package</h2>

        <p>
          Before you publish the package it is a good idea to test it. To do this you can use npm
          pack to create .tgz file. This file can be imported in another project to test the
          package.
          You can use the package by copying the .tgz file to the other project and running the
          following command:
        </p>

        <div className="my-2">

          {codeBlock({
            code: 'npm install vue-component-npm-example-2.0.0.tgz'
            , language: 'json', showLineNumbers: true
          })}

        </div>

        <p>After that you can use it by importing the component and the styling inside a vue file and using it.</p>

        <div className="my-2">
          {codeBlock({
            code: 'import { VueComponentNpmExample } from \'vue-component-npm-example\';\n' +
              'import \'vue-component-npm-example/dist/style.css\';\n' +
              '... \n' +
              '<VueComponentNpmExample msg="test" />'
            , language: 'json', showLineNumbers: true
          })}
        </div>

        <h2 className="text-3xl font-bold py-3">Publishing the component on npm</h2>
        <p>Any time you publish a new version you need to make sure to update the version in the
          package.json file. You can read more about this <a
            href="https://docs.npmjs.com/about-semantic-versioning"
            className="text-blue-800 underline font-bold">here</a>. for this first version it's fine
          to leave it at 1.0.0.</p>

        <p>Next, run the build command.</p>

        <div className="my-2">
          {codeBlock({
            code: 'npm run build'
          })}
        </div>

        <p>Before you can publish your package you need to be logged in to your npm account. If you
          don't have an account yet, make one first. If you have an account you can use npm adduser
          to log in.</p>

        <div className="my-2">
          {codeBlock({
            code: 'npm adduser'
          })}
        </div>

        <p>The final step is publishing the package.</p>

        <div className="my-2">
          {codeBlock({
            code: 'npm publish'
          })}
        </div>

        <p>Check if the project successfully published on npm. To do this click on your pfp on <a
          href="https://npmjs.com" className="text-blue-800 underline font-bold">npm</a> and then go
          to packages. it should be there.</p>

        <p>You can now install it from npm and use it in a project!</p>

        <h3 className="text-xl font-bold mt-4 text-blue-800 underline"><a
          href="https://github.com/mve/vue-component-npm-example">GitHub code</a></h3>
        <h3 className="text-xl font-bold text-blue-800 underline"><a
          href="https://www.npmjs.com/package/vue-component-npm-example">npm package</a></h3>

      </div>
    </div>
  )

}

export default VueComponentToNpmPackage;
